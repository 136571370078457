





























import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import QuestionsLayout from '@/layouts/question/QuestionsLayout.vue';
import ImportQuestionsFormTemplate from '@/components/question/ImportQuestionsFormTemplate.vue';
import { CreateQuestionBankQuestions, ImportQuestionsBody } from '@/models/question_banks';
import { FormStores } from '@/store/forms';
import { SubjectStores } from '@/store/subjects';
import { QuestionTypeNames } from '@/utils/constants';
import { DropdownItem } from '@/models/dropdown';
import { ChapterStores } from '@/store/chapters';
import { QuestionStores } from '@/store/questions';
import { ToastProgrammatic } from 'buefy';
import { QuestionBankStores } from '@/store/question_banks';

@Component({
    components: { Page, QuestionsLayout, ImportQuestionsFormTemplate },
})
export default class AddQuestion extends Vue {
    subjectListStore = SubjectStores.list;
    levelListStore = FormStores.list;
    chapterListStore = ChapterStores.list;
    questionCreateStore = QuestionStores.create;
    questionStateStore = QuestionStores.state;
    questionBankListStore = QuestionBankStores.list;
    // Dropdowns
    questionSubjectList: DropdownItem[] = [];
    questionLevelList: DropdownItem[] = [];
    questionBankList: DropdownItem[] = [];
    questionChapterList: DropdownItem[] = [];
    // Autofill variables
    questionSubjectPrefixId = -1;
    questionLevelPrefixId = -1;
    isQuestionBankListAutofillAllowed = false;
    questionBankListPrefixId = -1;
    questionChapterPrefixId = -1;
    
    currentFormTemplate: CreateQuestionBankQuestions = {
        questionType: ``,
        availability: false,
        subjectId: -1,
        formId: -1,
        questionBankId: -1,
        chapterId: -1,
        questionName: '',
        questionText: ``,
        defaultMark: -1,
        generalFeedback: ``,
        answerText: ``,
        requireText: ``,
        questionAsset: null,
    };

    get title() {
        return `Import Questions`;
    }

    get subjects() {
        return this.questionSubjectList;
    }

    get levels() {
        return this.questionLevelList;
    }

    get questionBanks() {
        return this.questionBankList;
    }

    get chapters() {
        return this.questionChapterList;
    }

    mounted() {
        switch(this.questionStateStore.questionBankManagementFolderState.length) {
            case 2: {
                this.questionSubjectPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 1].id;
                this.retrieveLevels(this.questionSubjectPrefixId);
                break;
            }
            case 3: {
                this.questionSubjectPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 2].id;
                this.questionLevelPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 1].id;
                this.retrieveLevels(this.questionSubjectPrefixId);
                this.retrieveQuestionBankListByFormId(this.questionLevelPrefixId);
                break;
            }
            case 4: {
                this.questionSubjectPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 3].id;
                this.questionLevelPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 2].id;
                this.questionChapterPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 1].id;
                this.retrieveLevels(this.questionSubjectPrefixId);
                this.retrieveQuestionBankListByFormId(this.questionLevelPrefixId);
                
                // Using this specific chapter id, we will need to find the question bank
                this.isQuestionBankListAutofillAllowed = true;
                this.questionBankListStore.retrieveQuestionBanks({ formid: this.questionLevelPrefixId, chapterid: this.questionChapterPrefixId });
                break;
            }
            default: {
                break;
            }
        }
        this.subjectListStore.retrieveSubjects({});
    }

    retrieveLevels(subjectId: number) {
        this.levelListStore.retrieveForms({ subjectid: subjectId });
    }

    retrieveQuestionBankListByFormId(formId: number) {
        this.questionBankListStore.retrieveQuestionBanks({ formid: formId });
    }

    retrieveChaptersByQuestionBankId(questionBankId: number) {
        this.chapterListStore.retrieveChaptersByQuestionBankId(questionBankId);
    }

    @Watch(`subjectListStore.response`)
    updateSubjectDropdown() {
        if (this.subjectListStore.response) {
            this.questionSubjectList = [];
            this.subjectListStore.response.objects.forEach((item) => {
                this.questionSubjectList.push({
                    text: item.subjectName,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    @Watch(`levelListStore.response`)
    updateFormDropdown() {
        if (this.levelListStore.response) {
            this.questionLevelList = [];
            this.levelListStore.response.objects.forEach((item) => {
                this.questionLevelList.push({
                    text: item.formName,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    @Watch(`questionBankListStore.response`)
    updateQuestionBanks() {
        if (this.questionBankListStore.response) {
            this.questionBankList = [];
            this.questionBankListStore.response.objects.forEach((data) => {
                if (data.questionType === QuestionTypeNames.mcq) {
                    this.questionBankList.push({
                        text: `${data.name} (Multiple Choice)`,
                        value: data.id.toString(),
                        uniqueKey: `${data.name}_${data.id}`,
                    });
                }

                if (data.questionType === QuestionTypeNames.essay) {
                    this.questionBankList.push({
                        text: `${data.name} (Essay)`,
                        value: data.id.toString(),
                        uniqueKey: `${data.name}_${data.id}`,
                    });
                }
            });
            // If the user selects until chapters, it means there will only be 1 question bank item
            if (this.questionStateStore.questionBankManagementFolderState.length === 4 && this.isQuestionBankListAutofillAllowed) {  
                // Find chapter first and map it back
                const chapterIndex = this.chapterListStore.response!.objects.findIndex((data) => {
                    return data.id === this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 1].id;
                });              
                if (chapterIndex > -1) {
                    const index = this.questionBankList.findIndex((data) => {
                        return Number(data.value) === this.chapterListStore.response!.objects[chapterIndex].questionbankid;
                    });
                    if (index > -1) {
                        this.questionBankListPrefixId = Number(this.questionBankList[index].value);
                        this.isQuestionBankListAutofillAllowed = false;
                        this.retrieveChaptersByQuestionBankId(this.questionBankListPrefixId);
                    }
                }
            }
        }
    }

    @Watch(`chapterListStore.response`)
    updateChapterDropdown() {
        if (this.chapterListStore.response) {
            this.questionChapterList = [];
            this.chapterListStore.response.objects.forEach((item) => {
                this.questionChapterList.push({
                    text: item.name,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    redirectToQuestionManagement() {
        this.$router.push({
            path: `/question_banks`
        });
    }

    async importQuestionsFileSource(input: ImportQuestionsBody) {
        this.questionCreateStore.importQuestions(input);
    }

    @Watch(`questionCreateStore.response`)
    checkResponse() {
        if (this.questionCreateStore.response) {
            ToastProgrammatic.open({
                type: `is-success`,
                duration: 3000,
                message: `Questions successfully imported. Returning to Question Bank Management`
            });
            this.questionStateStore.updateQuestionBankManagementIsModified(true);
            this.$router.push({
                path: `/question_banks`
            });
        }
    }
}
