








































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import ReadingMaterialUpload from '@/components/resources/ReadingMaterialUpload.vue';
import { ImportQuestionsBody } from '@/models/question_banks';
import { DropdownItem } from '@/models/dropdown';
import { uploadMediaFile } from '@/utils/attachments';

@Component({
  components: {
    Dropdown,
    ReadingMaterialUpload,
  },
})
export default class ImportQuestionsFormTemplate extends Vue {
  // Category Dropdowns
  selectedQuestionSubject = '- Select -';
  selectedQuestionLevel = '- Select -';
  selectedQuestionBankList = '- Select -';
  selectedQuestionChapter = '- Select -';
  isQuestionLevelDropdownDisabled = true;
  isQuestionBankListDropdownDisabled = true;
  isQuestionChapterDropdownDisabled = true;

  importQuestionsBody: ImportQuestionsBody = {
    subjectId: -1,
    formId: -1,
    questionBankId: -1,
    chapterId: -1,
    fileUrl: ``,
  };
  isCurrentFormValid = false;

  // Edit Variables
  previousSelectedQuestionSubject = ``;
  previousSelectedQuestionLevel = ``;
  previousSelectedQuestionBankList = ``;
  previousSelectedQuestionChapter = ``;
  previousSelectedFile = ``;
  
  @Prop({ required: true })
  questionSubjectData!: DropdownItem[];

  @Prop({ required: true })
  questionLevelData!: DropdownItem[];

  @Prop({ required: true })
  questionBankListingData!: DropdownItem[];

  @Prop({ required: true })
  questionChapterData!: DropdownItem[];

  @Prop()
  questionSubjectPrefixId!: number;

  @Prop()
  questionLevelPrefixId!: number;

  @Prop()
  questionBankListPrefixId!: number;

  @Prop()
  questionChapterPrefixId!: number;

  get subjects() {
    return this.questionSubjectData;
  }

  get levels() {
    return this.questionLevelData;
  }

  get questionBankListings() {
    return this.questionBankListingData;
  }

  get chapters() {
    return this.questionChapterData;
  }

  get uploadFileTypes() {
    return [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
      'text/xml',
    ];
  }

  get invalidUploadMessage() {
    return `Invalid File Type. Please upload a .docx or .xml file.`;
  }

  get QuestionTextConfig() {
    return {
      height: 300,
      menubar: false,
      external_plugins: {
        tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
      },
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor |\
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help |\
                tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry',
      htmlAllowedTags: ['.*'],
      htmlAllowedAttrs: ['.*'],
      draggable_modal: true,
    };
  }

  beforeCreate() {
    const script = document.createElement(`script`);
    script.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
    document.head.appendChild(script);
  }

  @Watch(`questionSubjectPrefixId`)
  autofillSubjectSelection() {
    if (this.questionSubjectPrefixId > -1) {
      this.previousSelectedQuestionSubject = this.questionSubjectPrefixId.toString();
    }
  }

  @Watch(`questionLevelPrefixId`)
  autofillLevelSelection() {
    if (this.questionLevelPrefixId > -1) {
      this.previousSelectedQuestionLevel = this.questionLevelPrefixId.toString();
    }
  }

  @Watch(`questionBankListPrefixId`)
  autofillQuestionBankListSelection() {
    if (this.questionBankListPrefixId > -1) {
      this.previousSelectedQuestionBankList = this.questionBankListPrefixId.toString();
    }
  }

  @Watch(`questionChapterPrefixId`)
  autofillChapterSelection() {
    if (this.questionChapterPrefixId > -1) {
      this.previousSelectedQuestionChapter = this.questionChapterPrefixId.toString();
    }
  }

  // Update Functions
  updateQuestionSubjectState(data: DropdownItem) {
    this.selectedQuestionSubject = data.text;
    this.importQuestionsBody.subjectId = parseInt(data.value);
    this.isQuestionLevelDropdownDisabled = false;
  }

  updateQuestionSubject(data: DropdownItem) {
    this.updateQuestionSubjectState(data);
    this.isQuestionLevelDropdownDisabled = true;
    this.validateCurrentForm();
    if (this.importQuestionsBody.subjectId > 0) {
      this.isQuestionLevelDropdownDisabled = false;
      this.isQuestionChapterDropdownDisabled = true;
      this.isQuestionBankListDropdownDisabled = true;
      this.selectedQuestionLevel = '- Select -';
      this.selectedQuestionBankList = '- Select -';
      this.selectedQuestionChapter = '- Select -';
      this.importQuestionsBody.formId = 0;
      this.importQuestionsBody.questionBankId = 0;
      this.importQuestionsBody.chapterId = 0;
      this.previousSelectedQuestionLevel = '';
      this.previousSelectedQuestionBankList = '';
      this.previousSelectedQuestionChapter = '';
      this.retrieveLevelDropdown();
    }
  }

  updateQuestionLevelState(data: DropdownItem) {
    this.selectedQuestionLevel = data.text;
    this.importQuestionsBody.formId = parseInt(data.value);
    this.isQuestionBankListDropdownDisabled = false;
  }

  updateQuestionLevel(data: DropdownItem) {
    this.updateQuestionLevelState(data);
    this.isQuestionBankListDropdownDisabled = true;
    this.isQuestionChapterDropdownDisabled = true;
    this.validateCurrentForm();
    if (this.importQuestionsBody.formId > 0) {
      this.isQuestionChapterDropdownDisabled = true;
      this.isQuestionBankListDropdownDisabled = false;
      this.selectedQuestionBankList = '- Select -';
      this.selectedQuestionChapter = '- Select -';
      this.importQuestionsBody.questionBankId = 0;
      this.importQuestionsBody.chapterId = 0;
      this.previousSelectedQuestionBankList = '';
      this.previousSelectedQuestionChapter = '';
      this.retrieveQuestionBankLevelDropdown();
    }
  }

  updateQuestionBankListState(data: DropdownItem) {
    this.selectedQuestionBankList = data.text;
    this.importQuestionsBody.questionBankId = Number(data.value);
    this.isQuestionChapterDropdownDisabled = false;
  }

  updateQuestionBankList(data: DropdownItem) {
    this.updateQuestionBankListState(data);
    this.isQuestionChapterDropdownDisabled = true;
    if (this.importQuestionsBody.formId > 0) {
      this.isQuestionChapterDropdownDisabled = false;
      this.selectedQuestionChapter = '- Select -';
      this.importQuestionsBody.chapterId = 0;
      this.previousSelectedQuestionChapter = '';
      this.retrieveChapterDropdown();
    }
  }

  updateQuestionChapterState(data: DropdownItem) {
    this.selectedQuestionChapter = data.text;
    this.importQuestionsBody.chapterId = parseInt(data.value);
  }

  updateQuestionChapter(data: DropdownItem) {
    this.updateQuestionChapterState(data);
    this.validateCurrentForm();
  }

  // Validation
  validateCurrentForm() {
    this.isCurrentFormValid = true;

    if (
      this.importQuestionsBody.subjectId < 0 ||
      this.importQuestionsBody.formId < 0 ||
      this.importQuestionsBody.questionBankId < 0 ||
      this.importQuestionsBody.chapterId < 0 ||
      this.importQuestionsBody.fileUrl.length === 0
    ) {
      this.isCurrentFormValid = false;
    }
  }

  async onFileUpload(payload: File | null) {
    if (!payload) {
      return;
    }
    
    const fileUrl = await uploadMediaFile(payload as File);
    this.importQuestionsBody.fileUrl = fileUrl;
    this.validateCurrentForm();
  }

  // Emits
  @Emit(`retrieveLevels`)
  retrieveLevelDropdown() {
    return this.importQuestionsBody.subjectId;
  }

  @Emit(`retrieveQuestionBankListByFormId`)
  retrieveQuestionBankLevelDropdown() {
    return this.importQuestionsBody.formId;
  }

  @Emit(`retrieveChaptersByQuestionBankId`)
  retrieveChapterDropdown() {
    return this.importQuestionsBody.questionBankId;
  }

  @Emit(`import`)
  importQuestionsFileSource() {
    return this.importQuestionsBody;
  }

  redirectToQuestionManagement() {
    this.$router.push({
      path: `/question_banks`,
    });
  }
}
